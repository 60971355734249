<template>
  <div class="home">
    <div class="all">
      <div class="about">
        <div class="title">
          <div v-if="$i18n.locale != 'en-us'">关于我们</div>
          <div v-else>About Us</div>
        </div>
        <div class="content" v-if="$i18n.locale != 'en-us'">{{personageIntroduction || '古生物综合数据库，是一个集化石图像、3D模型、全景图、断层扫描、参考文献和专业分类于一体的综合性化石资源库。该平台以数字化手段为核心，为化石爱好者、研究人员以及教育机构提供了一个全面、系统、易于访问且极具深度的化石资源库。在数据库的基础上，继续收集、整理国内外公开发表（或内部资料）古生物学和地层学领域的资料与数据，并在数据的存储、共享、分析等方面与国内外的组织与机构开展交流与合作，为地球科学领域的基础科学研究、油气矿藏的生产实践以及面向公众的科学传播而服务。共享数据包含11,435条地层剖面分布数据，260,6976条化石产出数据和102,307条古生物分类名录数据，10,128幅化石模式标本图像数据，10,106条古生物学和地层学科学文献数据，20,136个古生物形态性状数据。下载地址：http://data.fossil-ontology.com:8080/space/fossilImagedata'}}</div>
        <div class="content" v-else>
         {{enpersonageIntroduction||'The Fossil Ontology Database (http://fossil-ontology.com/home/newpage) is a comprehensive fossil resource library that integrates fossil images, 3D models, panoramas, tomographic scans, references, and professional classifications. With digitalization as its core, this platform provides a comprehensive, systematic, easily accessible, and highly in-depth fossil resource library for fossil enthusiasts, researchers, and educational institutions. Building upon this database, it continues to collect and organize publicly published (or internal) materials and data in the fields of paleontology and stratigraphy from both domestic and international sources. It also engages in collaboration and exchange with organizations and institutions domestically and internationally in terms of data storage, sharing, and analysis. These efforts serve to support basic scientific research in the field of earth sciences, production practices related to oil and gas deposits, and science communication targeted at the public. The shared data includes 11,435 stratigraphic profile distribution data entries, 2,66,976 fossil occurrence data entries, 102,307 paleontology taxonomy records, 10,128 fossil type specimen image data entries, 10,106 paleontology and stratigraphy scientific literature entries, and 20,136 paleontological morphological trait data entries. Download address: http://data.fossil-ontology.com:8080/space/fossilImagedata'}} 
        </div>
      </div>
      <div class="person">
        <div v-for="item in perlist" :key="item.id" class="single" @click="godetail(item)">
          <img :src="item.picture" alt="">
          <div class="content">
            <div class="name">{{ item.name }}</div>
            <div class="intro">{{ item.selfIntroduction }}</div>
            <div class="location">{{ item.location }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import HomeApi from '../../api/home/index'
export default {
  name: '',
  components: {

  },
  // 定义属性
  data() {
    return {
      perlist: [],
      personageIntroduction:'',
      enpersonageIntroduction:''
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    init() {
      HomeApi.gitsystem(1).then(res => {
        if(res.data.personageIntroduction) this.personageIntroduction = res.data.personageIntroduction
        this.perlist = res.data.teamIntroduction
      })
      HomeApi.gitsystem(2).then(res => {
        if(res.data.personageIntroduction) this.enpersonageIntroduction = res.data.personageIntroduction
      })
    },
     godetail(data){
      let objData = JSON.stringify(data)
      this.$router.push({
        path:'aboutd',
        query:{detail: encodeURIComponent(objData)}

      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.init()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.home {
  background: #EDF4F8;
  box-sizing: border-box;
  padding-top: 25px;
  padding-bottom: 80px;
  overflow-y: auto;
}

.all {
  width: 73%;
  margin: 0 auto;
  background: #EDF4F8;
 

  .about {
    background: #fff;
     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
    .title {
      border-bottom: 1px solid #ddd;

      div {
        padding: 10px 25px;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
      }
    }

    .content {
      text-indent: 35px;
      padding: 10px 25px;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
    }
  }

  .person {
    
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
    .single {
      padding: 20px;
      margin-bottom: 15px;
      box-sizing: border-box;
      width: 49%;
      background: #FFFFFF;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      img {
        width: 170px;
        height: 220px;
        object-fit: cover;
      }

      .content {
        margin-left: 20px;
        flex: 1;
        display: flex;
        flex-flow: column;
        position: relative;

        .name {
          font-weight: 600;
          font-size: 20px;
          color: #333333;
        }

        .intro {
          margin-top: 12px;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
        }

        .location {
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}
</style>