import request from "../request";
// 获取首页配置信息
function gitsystem(type) {
  return request({
    url: "/system/site/"+type,
    method: "get",
  });
}

// 获取新闻列表
function gitnews(params) {
    return request({
      url: "/image/news",
      method: "get",
      params,
    });
}


  // 获取新闻列表
function newdetails(id) {
    return request({
      url: "/image/news/"+id,
      method: "get",
    });
}




export default {
    gitsystem,
    gitnews,
    newdetails
};
